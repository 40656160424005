import './Calendar.scss';

export default function Cal() {
  return (
    <div className="inline-calendar">
      <div className="container">
        <div className="calendar-month block">
          <div className="arrow-btn-container">
            <h2 className="titular"><span>2021년 12월</span><span>토. 오후 1:00</span></h2>
          </div>
          <table className="calendar">
            <thead className="days-week">
            <tr>
              <th>일</th>
              <th>월</th>
              <th>화</th>
              <th>수</th>
              <th>목</th>
              <th>금</th>
              <th>토</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="disabledDay"><a className="scnd-font-color" href="#104">28</a></td>
              <td className="disabledDay"><a className="scnd-font-color" href="#105">29</a></td>
              <td className="disabledDay"><a className="scnd-font-color" href="#106">30</a></td>
              <td><a className="scnd-font-color" href="#107">1</a></td>
              <td><a className="scnd-font-color" href="#101">2</a></td>
              <td><a className="scnd-font-color" href="#102">3</a></td>
              <td><a className="scnd-font-color" href="#103">4</a></td>
            </tr>
            <tr>
              <td><a className="scnd-font-color" href="#104">5</a></td>
              <td><a className="scnd-font-color" href="#105">6</a></td>
              <td><a className="scnd-font-color" href="#106">7</a></td>
              <td><a className="scnd-font-color" href="#107">8</a></td>
              <td><a className="scnd-font-color" href="#108">9</a></td>
              <td><a className="scnd-font-color" href="#109">10</a></td>
              <td className="selectedDay"><a className="scnd-font-color" href="#110">11</a></td>
            </tr>
            <tr>
              <td><a className="scnd-font-color" href="#111">12</a></td>
              <td><a className="scnd-font-color" href="#112">13</a></td>
              <td><a className="scnd-font-color" href="#113">14</a></td>
              <td><a className="scnd-font-color" href="#114">15</a></td>
              <td><a className="scnd-font-color" href="#115">16</a></td>
              <td><a className="scnd-font-color" href="#116">17</a></td>
              <td><a className="scnd-font-color" href="#117">18</a></td>
            </tr>
            <tr>
              <td><a className="scnd-font-color" href="#118">19</a></td>
              <td><a className="scnd-font-color" href="#119">20</a></td>
              <td><a className="scnd-font-color" href="#120">21</a></td>
              <td><a className="scnd-font-color" href="#121">22</a></td>
              <td><a className="scnd-font-color" href="#122">23</a></td>
              <td><a className="scnd-font-color" href="#123">24</a></td>
              <td><a className="scnd-font-color" href="#124">25</a></td>
            </tr>
            <tr>
              <td><a href="#125">26</a></td>
              <td><a href="#126">27</a></td>
              <td><a href="#127">28</a></td>
              <td><a href="#128">29</a></td>
              <td><a href="#129">30</a></td>
              <td><a className="scnd-font-color" href="#101">31</a></td>
              <td className="disabledDay"><a className="scnd-font-color" href="#101">1</a></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}