import intro from './intro_pic_draw.png';
import origin from './IMG_5412.jpg';
import map from './map.png';
import './App.scss';
import Calendar from './Calendar.js';
import styled from '@emotion/styled';
import {useEffect, useState} from 'react';

import {
  AppBar,
  Box,
  CssBaseline,
  IconButton,
  ImageList,
  ImageListItem,
  Link,
  Slide,
  Toolbar,
  useScrollTrigger
} from "@mui/material";
import PropTypes from "prop-types";

function HideOnScroll(props) {
  const {children, window} = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function App(props) {
  const [BankData, setBankData] = useState('')
  const changeBankInfo = (inputType) => setBankData(inputType)
  const BankInfo = styled.div`
    display: ${props => !props.show ? 'none' : 'block'}
  `

  const [scrollY, setScrollY] = useState(0);
  const changeScrollY = () => setScrollY(window.pageYOffset);

  useEffect(() => {
    function watchScroll() {
      window.addEventListener('scroll', changeScrollY);
    }
    watchScroll();
    return () => {
      window.removeEventListener('scroll', changeScrollY);
    };
  });

  return (
    <div className="App">
      <Box sx={{flexGrow: 1}}>
        <CssBaseline/>
        <HideOnScroll {...props}>
          <AppBar position="fixed"
                  style={{
                    background: '#FFFFFF',
                    color: '#333',
                    boxShadow: "none",
                  }}
          >
            <Toolbar>
              <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{
                    mr: 2,
                    margin: '0 auto'
                  }}
              >
                <span className="titleName">혜란 💝 정욱</span>
              </IconButton>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      </Box>
      <section id="hero">
        <img src={intro} alt="Hero Draw Pic"/>
        <img src={origin} alt="Hero Origin Pic" style={{...{opacity: scrollY < 100 ? scrollY * 0.01 : 1.0 }}}/>
        <div style={{height: 380}}></div>
        <div style={{
          position: "relative",
          width: '100%',
          backgroundColor: 'rgba(80,80,80,0.6)',
          padding: '10px 0',
          color: '#fff',
          fontSize: '1.4rem'
        }}>
          2021년 12월 11일 토요일 낮 1시<br/>
          KRX 한국거래소 국제회의장
        </div>
      </section>
      <section id="introduce">
        <p><strong>황승환∙︎김선자</strong>의 장남 <strong className="name">정욱</strong></p>
        <p><strong>문용규∙김태경</strong>의 차녀 <strong className="name">혜란</strong></p>
        <p>함께여서 따뜻한 겨울,</p>
        <p>저희 결혼합니다. ❤️</p>
      </section>
      <section id="invitation">
        <span>Wedding Invitation</span>
        <h2>초대합니다</h2>
        <div>
          <p><code>365 * 7 + 1 = 2556</code></p>
          <p>2556일이라는 시간 동안</p>
          <p>쌓아온 빅데이터 분석 결과,</p>
          <p>우리는 미래가 유망한 한쌍이</p>
          <p>될 것이라 판단하였습니다.</p>
          <p>결혼이라는 호재로 <span>떡상</span>할 우리에게</p>
          <p>따뜻한 축복을 투자해주십시오.</p>
        </div>
      </section>
      <section id="cal">
        <span>Calendar</span>
        <h2>우리가 하나 되는 날</h2>
        <Calendar/>
      </section>
      <section id="photo">
        <span>Photography</span>
        <h2>사진</h2>
        <div className="wrapper">
          <ImageList variant="masonry" cols={2} gap={8}>
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
        <Link href="https://adobe.ly/3mEhweJ">
          <button>더 많은 사진 보기</button>
        </Link>
      </section>
      <section id="location">
        <span>Location</span>
        <h2>오시는 길</h2>
        <p>KRX 한국거래소 서울사무소 국제회의장</p>
        <p>서울 영등포구 여의나루로 76(여의도동33)</p>
        <p>TEL 02-3774-9000</p>
        <img src={map} alt="한국거래소"/>
        <div>
          <Link href="http://naver.me/5sclAhmI" target="_blank">
            <button>네이버 지도</button>
          </Link>
          <Link href="http://kko.to/jeXCDDE4B" target="_blank">
            <button>카카오 지도</button>
          </Link>
          <Link href="https://maps.app.goo.gl/eTHks1hBLLsDPkwp9" target="_blank">
            <button>구우글 지도</button>
          </Link>
        </div>
      </section>
      {/*<section id="contact">*/}
      {/*  <span>Contract Info</span>*/}
      {/*  <h2>연락처 정보</h2>*/}
      {/*  <div>*/}
      {/*    <a href="tel:010-2185-0819">황정욱</a>, <a href="tel:010-3792-0034">황승환</a>, <a href="tel:010-3908-7970">김선자</a>*/}
      {/*  </div>*/}
      {/*  <div>*/}
      {/*    <a href="tel:010-9722-2476">문혜란</a>, <a href="tel:010-0000-0000">문용규</a>, <a href="tel:010-2476-2162">김태경</a>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <section id="give">
        <span>Give Love</span>
        <h2>마음 전하실 곳</h2>
        <div>
          <button onClick={() => changeBankInfo('m')}>신랑측 계좌번호 보기</button>
          <button onClick={() => changeBankInfo('w')}>신부측 계좌번호 보기</button>
        </div>
        <BankInfo show={BankData === 'm' ? true : false} className="account boy">
          <p>혼주 황승환<br/>(국민은행) 068-21-0541-831</p>
          <p>신랑 황정욱<br/><span style={{fontSize:'1.1rem', color:'black'}}>(카카오페이증권)</span> 020-00-076649</p>
        </BankInfo>
        <BankInfo show={BankData === 'w' ? true : false} className="account girl">
          <p>혼주 김태경<br/>(기업은행) 420-011427-01-019</p>
          <p>신부 문혜란<br/>(카카오뱅크) 3333-02-9158588</p>
        </BankInfo>
        <div className="from-jerry">
          <p>∙</p>
          <p>모바일 청첩장 다른 버전 <a href="https://wedding-luke.vercel.app/" target="_blank" rel="noopener">보기</a> - Provided by Jerry</p>
        </div>
      </section>
      <footer>정욱 <span>❤</span> 혜란 all rights reserved</footer>
    </div>
  )
}

const itemData = [
  {
    img: './images/DSC00005.jpg'
  },
  {
    img: './images/DSC00042.jpg'
  },
  {
    img: './images/DSC00074.jpg'
  },
  {
    img: './images/DSC00385.jpg'
  },
  {
    img: './images/DSC00742.jpg'
  },
  {
    img: './images/DSC00757.jpg'
  },
  {
    img: './images/DSC00795.jpg'
  },
  {
    img: './images/DSC01022.jpg'
  },
  {
    img: './images/JW.jpg'
  },
];

export default App;